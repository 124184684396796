import React, { ReactElement, useState } from 'react';
import { IoCloseCircle } from 'react-icons/io5';
import { CabButton, CabIcon } from '@CabComponents';
import { CabModal } from '@CabComponents/CabModal';

interface ComponentProps {
  label: string;
  assetLabel: string;
  onDidDismiss: () => void;
  onDelete: () => void;
  disabled: boolean;
}

type Props = ComponentProps;

export const ModalDeleteButton = ({ label, assetLabel, onDelete, onDidDismiss, disabled }: Props): ReactElement => {
  const [deleteMode, setDeleteMode] = useState(false);
  const [confirmDeleteMode, setConfirmDeleteMode] = useState(false);

  const handleDeleteClick = (): void => {
    setDeleteMode(true);
  };

  const handleDeleteConfirm = (): void => {
    setDeleteMode(false);
    setConfirmDeleteMode(true);
  };

  const handleDelete = (): void => {
    onDelete();
    onDidDismiss();
  };

  return (
    <>
      <CabButton buttonType='tertiary' color='primary' onClick={handleDeleteClick} disabled={disabled}
        icon={<CabIcon alt='Delete' Icon={IoCloseCircle} />}
      >
        {label}
      </CabButton>

      <CabModal
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        title={`Are you sure you want to delete this ${assetLabel}?`}
        text="All of the information you've stored will be deleted and unrecoverable."
        isAlert={true}
        noFullScreen={true}
        actionButtons={
          <>
            <CabButton buttonType='tertiary' color='primary' onClick={() => setDeleteMode(false)}>
              Cancel
            </CabButton>
            <CabButton buttonType='primary' color='primary' onClick={handleDeleteConfirm}>
              Delete
            </CabButton>
          </>
        }
      />

      <CabModal
        open={confirmDeleteMode}
        onClose={() => setConfirmDeleteMode(false)}
        text={`This cannot be undone. Are you sure you want to permanently delete this ${assetLabel}?`}
        isAlert={true}
        noFullScreen={true}
        actionButtons={
          <>
            <CabButton buttonType='tertiary' color='primary' onClick={() => setConfirmDeleteMode(false)}>
              Cancel
            </CabButton>
            <CabButton buttonType='primary' color='primary' onClick={handleDelete}>
              Delete
            </CabButton>
          </>
        }
      />
    </>
  );
};

export default ModalDeleteButton;