import { ReactElement, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatchType, actions, ProfileCategory, Leader } from '../../store';
import { getProfileCategoryTemplate } from '../../store/templates';
import { PROFILE_CATEGORY_MAX_LENGTH, PAGE_URL, EVENT_TYPE } from '../../constants';
import ModalDeleteButton from '../Common/ModalDeleteButton';
import { trackEvent } from '../../utils/appAnalyticsUtils';
import IconPicker from '../Pickers/IconPicker';
import { CabinetIconData, CABINET_ICONS_LIST } from '../../icons';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton, CabTextInput } from '@CabComponents';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, FormLabel, Typography } from '@mui/material';
import colors from '../../colors';

interface ComponentProps {
  leader: Leader;
  category?: ProfileCategory;
  isOpen: boolean;
  onDidDismiss: () => void;
}

type Props = ComponentProps;

export const EditProfileCategory = ({
  leader, category, isOpen, onDidDismiss
}: Props): ReactElement => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [iconPickerOpen, setIconPickerOpen] = useState(false);
  const [editedCategory, setEditedCategory] = useState({
    ...(category || getProfileCategoryTemplate()),
    leader: leader.id,
  });
  const dispatch = useDispatch<ThunkDispatchType>();
  const createProfileCategory = useCallback((newCategory: ProfileCategory) => 
    dispatch(actions.profileNotes.createProfileCategory(newCategory)), [dispatch]);
  const updateProfileCategory = useCallback((updatedCategory: ProfileCategory) => 
    dispatch(actions.profileNotes.updateProfileCategory(updatedCategory)), [dispatch]);
  const deleteProfileCategory = useCallback((deletedCategory: ProfileCategory) => 
    dispatch(actions.profileNotes.deleteProfileCategory(deletedCategory)), [dispatch]);

  const navigate = useNavigate();

  useEffect(() => {
    setEditedCategory(category || { ...getProfileCategoryTemplate(), leader: leader.id });
  }, [category, isOpen, leader]);

  const handleSave = (): void => {
    setButtonDisabled(true);
    let saveFunc = createProfileCategory;
    let eventName = EVENT_TYPE.CREATE_EXEC_CATEGORY;
    if (editedCategory.id > -1) {
      saveFunc = updateProfileCategory;
      eventName = EVENT_TYPE.UPDATE_EXEC_CATEGORY;
    }
    saveFunc(editedCategory).then(() => {
      setButtonDisabled(false);
      trackEvent(eventName);
      onDidDismiss();
    });
  };

  const handleDelete = (): void => {
    deleteProfileCategory(editedCategory).then(() => {
      trackEvent(EVENT_TYPE.DELETE_EXEC_CATEGORY);
      navigate(`${PAGE_URL.EXECUTIVE}/${leader.id}`);
    });
  };

  const handleSelectIcon = (icon: CabinetIconData): void => {
    setEditedCategory(prev => ({
      ...prev,
      icon_url: icon.icon_url,
    }));
    setIconPickerOpen(false);
  };

  const handleChangeTitle = (newTitle: string | null | undefined): void => {
    setEditedCategory(prev => ({...prev, title: newTitle?.slice(0, PROFILE_CATEGORY_MAX_LENGTH) || ''}));
  };

  return (
    <>
      <CabModal
        open={isOpen}
        onClose={onDidDismiss}
        title={`${editedCategory.id > -1 ? 'Edit' : 'Add'} Category`}
        closeIcon={true}
        closeOnBackdropClick={true}
        actionButtons={
          <>
            <CabButton buttonType='tertiary' color='primary' onClick={onDidDismiss} disabled={buttonDisabled}>
              Cancel
            </CabButton>
            {editedCategory.id > -1 && 
            <ModalDeleteButton label="Delete Category" assetLabel="category" 
              onDelete={handleDelete} onDidDismiss={onDidDismiss} 
              disabled={!leader.permissions.categories[editedCategory.id]?.delete}/>
            }
            <CabButton buttonType='primary' onClick={handleSave} disabled={buttonDisabled || !editedCategory.title}>
              Save
            </CabButton>
          </>
        }
      >
        <Box display='flex' flexDirection='column' width='100%' paddingTop={2}>
          <CabButton buttonType='text' sx={{height: '100%', padding: 3}}
            onClick={() => setIconPickerOpen(!iconPickerOpen)}
          >
            <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
              <Box component='img' src={editedCategory.icon_url} height={64} />
              <Typography fontWeight='bold' sx={{color: colors.navyDark}}>Change Icon</Typography>
            </Box>
          </CabButton>

          <FormControl fullWidth>
            <FormLabel>Page Title</FormLabel>
            <CabTextInput
              value={editedCategory.title}
              onChange={(e) => handleChangeTitle(e.target.value)}
              placeholder='Category Title'
            />
          </FormControl>
        </Box>
      </CabModal>

      <IconPicker
        showModal={iconPickerOpen}
        icons={CABINET_ICONS_LIST.filter(i => i.showInPicker)}
        onSelect={data => handleSelectIcon(data as CabinetIconData)}
        onCancel={() => setIconPickerOpen(false)}
      />
    </>
  );
};

export default EditProfileCategory;